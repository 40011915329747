import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Container from "layouts/Container";

import "./FullPageHero.scss";

const FullPageHero = ({ text, bgImage }) => {
  return (
    <div className="full-page-hero">
      <div className="full-page-hero__image">
        <GatsbyImage
          className="full-page-hero__gatsby-image-wrapper"
          image={getImage(bgImage)}
          style={{ transform: "scale(1.6)" }}
          alt=""
          objectPosition="19% 50%"
        />
      </div>

      <div className="full-page-hero__content">
        <Container>
          <p className="full-page-hero__text t-white t-bold">{text}</p>
        </Container>
      </div>
    </div>
  );
};

export default FullPageHero;
