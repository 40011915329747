import React from "react";
import { graphql } from "gatsby";
import SEO from "@w3f/gatsby-theme-w3f/src/components/site/SEO";
import Layout from "components/site/Layout";
import FullPageHero from "../components/FullPageHero";

const HomePage = ({ data: { page, bgImage } }) => {
  const { intro, seo } = page.frontmatter;
  const meta = seo ? { ...seo } : {}; // In case the seo object is null

  return (
    <Layout headerOptions={{ fixed: true }} isHomepage>
      <SEO {...meta} />

      <FullPageHero text={intro} bgImage={bgImage} />
    </Layout>
  );
};

export default HomePage;

export const query = graphql`
  query ($language: String!) {
    ...Locales
    page: markdownRemark(fields: { langKey: { eq: $language } }, fileAbsolutePath: { regex: "//pages/home.md/" }) {
      html
      frontmatter {
        intro
        seo {
          ...Seo
        }
      }
      fields {
        langKey
        slug
      }
    }
    bgImage: file(name: { eq: "home2-large" }) {
      ...BgImage
    }
  }
`;
